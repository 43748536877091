import { Control, useWatch } from 'react-hook-form';
import { BudgetFormModel } from '../Application/developmentApplication/Financing/DevelopmentBudget';
import { Cost, FinancingSource } from '../models';
import { otherPublicFundingCode } from '../constants/constants';
import { DeepPartial } from '@src/types/DeepPartial';
export function useDevelopmentBudget(control: Control<BudgetFormModel, Cost[]>) {
  const results = useWatch({ control });

  const applicantAmountTotal = sumAllPropertiesInCollection<DeepPartial<Cost>>(
    results?.costs ? results?.costs : [],
    ['applicantAmount'],
  );
  const participantsAmountTotal = sumAllPropertiesInCollection<DeepPartial<Cost>>(
    results?.costs ? results?.costs : [],
    ['participantsAmount'],
  );

  const totalParticipantAndApplicationAmount = applicantAmountTotal + participantsAmountTotal;

  const financingSourcesTotal = sumAllPropertiesInCollection<DeepPartial<FinancingSource>>(
    results?.financingSources ? results.financingSources : [],
    ['amount'],
  );

  const otherPublicFunding = results?.financingSources?.find((item) => item.type === otherPublicFundingCode);
  const otherPublicFoundingAmount = otherPublicFunding?.amount != null ? otherPublicFunding.amount : 0;

  return {
    applicantAmountTotal,
    participantsAmountTotal,
    totalParticipantAndApplicationAmount,
    financingSourcesTotal,
    otherPublicFoundingAmount,
  };
}

export function sumAllPropertiesInCollection<Collection>(
  collection: Array<Collection>,
  propertyKeys: Array<keyof Collection>,
): number {
  let totalValue = 0;

  collection.forEach((cost) => {
    propertyKeys?.forEach((propertyKey) => {
      if (propertyKey in cost) {
        const output = parseInt(String(cost[propertyKey]), 10);
        totalValue = totalValue + (Number.isNaN(output) ? 0 : output);
      }
    });
  });

  return totalValue;
}
