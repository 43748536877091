﻿import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { Header as BaseHeader } from '@in/component-library';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/nic_klynger_logo_positiv_nic-main.svg';
import css from './Header.module.scss';
import useUser from '../../hooks/user/use-user';
import useConfiguration from '../../hooks/configuration/use-configuration';
import { AppNavItems } from './constants';
import { ClusterStatusesArray } from '@src/constants/cluster-status';
import useAuth from '@hooks/authorization/use-auth';
import useMyCluster from '@hooks/cluster/use-my-cluster';
import useClusters from '@hooks/cluster/use-clusters';

declare type MenuItem = {
  href?: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
type NavItem = {
  href?: string;
  text: string | ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const Header: FC = () => {
  const { isUserLoggedIn, user, chosenClusterId } = useUser();
  const { clusterPortalUrl } = useConfiguration();
  const { cluster, isFetched } = useMyCluster();
  const { clusters, deleteChosenClusterMutation } = useClusters();
  const { checkAccess } = useAuth(chosenClusterId);
  const navigate = useNavigate();

  const loggedInAs = isUserLoggedIn ? user?.displayName : undefined;

  const handleOnClick = useCallback(
    (href: string) => () => {
      window.location.href = href;
    },
    [],
  );

  const logOutClickHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      deleteChosenClusterMutation.mutateAsync().finally(() => {
        window.location.href = '/sign-out';
      });
    },
    [deleteChosenClusterMutation],
  );

  const menuItems: Array<MenuItem> = useMemo(() => {
    const items = [
      {
        text: 'Generell veiledning',
        href: `/general-guidance`,
        onClick: () => navigate(`/general-guidance`),
      },
      {
        text: 'Logg ut',
        href: '/',
        onClick: logOutClickHandler,
      },
    ];
    if (clusters.length > 1) {
      items.unshift({
        text: 'Velg Aktør',
        href: '/cluster-chooser',
        onClick: () => navigate(`/cluster-chooser`),
      });
    }

    if (checkAccess('AccountSettings').read) {
      items.unshift({
        text: 'Kontoinnstillinger',
        href: '/account-settings',
        onClick: () => (window.location.href = `${clusterPortalUrl}account-settings`),
      });
    }

    return items;
  }, [logOutClickHandler, clusters.length, checkAccess, navigate, clusterPortalUrl]);

  const navItems = useMemo(() => {
    const navItems: NavItem[] = [];

    // Finn ut hvilken status valgte 'cluster' er
    const clusterStatus = ClusterStatusesArray.find((status) => cluster?.clusterStatus?.id === status.id);
    if (clusterStatus) {
      // Hent ut modulene som tilsvarer denne statusen
      const items = AppNavItems[clusterStatus.key];

      for (const item of items) {
        // Sjekk om den har en modul-access check
        if (item.module) {
          if (checkAccess(item.module).read) {
            navItems.push({
              text: item.text,
              href: `${clusterPortalUrl}${item.path}`,
              onClick: handleOnClick(item.path),
            });
          }
        } else {
          // Ingen modul-sjekk
          navItems.push({
            text: item.text,
            href: `${clusterPortalUrl}${item.path}`,
            onClick: handleOnClick(item.path),
          });
        }
      }
    }

    return navItems;
  }, [checkAccess, cluster, clusterPortalUrl, handleOnClick]);

  return (
    <>
      <BaseHeader
        logoImgSrc={logo}
        customLogo={
          <Link to="/" title="Til forsiden">
            <img src={logo} height="36px" width="81px" alt="logo" />
          </Link>
        }
        companyName={cluster?.name || ''}
        logoAriaLabel="Til forsiden"
        logoLink="/"
        name={loggedInAs}
        loggedInAsLabel="Innlogget som:"
        className={css['header']}
        menuItems={isFetched ? menuItems : undefined}
        navItems={isUserLoggedIn ? navItems : []}
      />
    </>
  );
};
