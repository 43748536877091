import React from 'react';
import css from './ErrorBoundary.module.scss';
import generateGuid from '../../utils/Guid';
import { Button, PageLoader, Theme } from '@in/component-library';
import { SeverityLevel } from '@microsoft/applicationinsights-common/src/Interfaces/Contracts/SeverityLevel';
import { appInsights } from '@src/ApplicationInsights/ApplicationInsights';

type Props = {
  children;
  message: string;
  isDevOrTest?: boolean;
  onError?: (
    error: Error,
    info: {
      componentStack: string;
    },
  ) => void;
  onResetKeysChange?: () => void;
  onReset?: (...args: Array<unknown>) => void;
  resetKeys?: string[];
  //t: TFunction<'buttons', undefined>;//TODO: implementer dette når språk er på plass
};

type State = {
  hasError: 'error' | 'chunkError' | 'noError';
  id?: string;

  error:
    | {
        message: string;
        stack: string;
      }
    | undefined;
};
const changedArray = function changedArray(a, b) {
  if (a === void 0) {
    a = [];
  }

  if (b === void 0) {
    b = [];
  }

  return (
    a.length !== b.length ||
    a.some(function (item, index) {
      return !Object.is(item, b[index]);
    })
  );
};

const initialState: State = { id: '', hasError: 'noError', error: undefined };
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromError(error): State {
    return { hasError: 'error', error: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const id = generateGuid();

    appInsights.trackException({
      id: id,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: { errorBoundaryExceptionId: id, ...errorInfo },
    });
    const chunkFailedMessage = /(?=.*Failed)(?=.*dynamically)(?=.*imported)(?=.*module)/;
    const alternativeChunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (
      error?.message &&
      (chunkFailedMessage.test(error.message) || alternativeChunkFailedMessage.test(error.message))
    ) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        this.setState({ hasError: 'chunkError', error: { message: '', stack: '' }, id: id });
        window.location.reload();
      }
    } else {
      this.setState({ ...this.state, id: id });
      this.props?.onError && this.props?.onError(error, errorInfo);
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const error = this.state.error;
    const resetKeys = this.props.resetKeys; // There's an edge case where if the thing that triggered the error
    // happens to *also* be in the resetKeys array, we'd end up resetting
    // the error boundary immediately. This would likely trigger a second
    // error to be thrown.
    // So we make sure that we don't check the resetKeys on the first call
    // of cDU after the error is set

    if (error !== null && prevState.error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
      let onResetKeysChange;

      (onResetKeysChange = this.props.onResetKeysChange) == null
        ? void 0
        : onResetKeysChange.call(this.props, prevProps.resetKeys, resetKeys);
      this.reset();
    }
  }

  reset() {
    this.setState(initialState);
  }

  render() {
    //const { t } = this.props;

    const ResetButton = () => {
      return (
        <Button
          type="button"
          theme={Theme.Neutral}
          onClick={() => {
            this.reset();
          }}
        >
          {/*<Trans t={t} ns="buttons" i18nKey={'errorBoundaryResetButton'}>*/}
          {/*  Fjern feilmelding*/}
          {/*</Trans>*/}
          Fjern feilmelding
        </Button>
      );
    };

    if (this.props.isDevOrTest && this.state.hasError === 'chunkError') {
      return <PageLoader aria-label="Laster data" />;
    }

    if (this.props.isDevOrTest && this.state.hasError === 'error') {
      return (
        <div className={css['error-boundary']}>
          <p>
            {this.props.message} (ID: {this.state.id})
          </p>

          <pre>
            <p>{this.state.error?.message}</p>
            <code>{this.state.error?.stack}</code>
          </pre>

          <ResetButton />
        </div>
      );
    }

    if (this.state.hasError === 'error') {
      return (
        <div>
          <h2>{this.props.message}</h2>
          <p>ID: {this.state.id}</p>
          <ResetButton />
        </div>
      );
    }

    return this.props.children;
  }
}
export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}
//export default withTranslation()(ErrorBoundary);//TODO: implementer dette når språk er på på plass
export default ErrorBoundary;
