import React, { useState } from 'react';
import cn from 'classnames';
import css from './dateinput.module.scss';
import { FaIcons, FormField, FormPageContext } from '@in/component-library';
import { FaIcon } from '@in/component-library/';
import generateGuid from '../../utils/Guid';

export type DateInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'autoComplete'> & {
  name: string;
  /**
   * Label for Date / From Date Field
   */
  label?: string;
  /**
   * Hide label visually. Still accessible for screen readers.
   */
  hideLabel?: boolean;
  /**
   * Hide label visually. Still accessible for screen readers.
   */
  showClearButton?: boolean;
  /**
   * Help text for Date / From Date Field
   */
  helpTextRich?: string;
  /**
   * Error message
   */
  errorMsg?: string;
  /**
   * Hide error message
   */
  hideError?: boolean;
  onClearDate?: () => void;
  /**
   * Display Calendar icon
   */
  showIcon?: boolean;
};

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      name,
      label,
      hideLabel = false,
      showClearButton = true,
      helpTextRich,
      errorMsg,
      hideError,
      showIcon = true,
      onClearDate,
      ...rest
    }: DateInputProps,
    ref,
  ) => {
    const { isOnFormPage } = React.useContext(FormPageContext);
    const [clearButtonVisible, setClearButtonVisible] = useState(false);
    const id = rest?.id ? rest.id : generateGuid();

    return (
      <FormField
        htmlFor={id}
        helpTextRich={helpTextRich}
        label={label}
        hideLabel={hideLabel}
        errorMessage={errorMsg}
        hideError={hideError}
        className={cn(css['text-input'], rest.className, {
          [css['text-input--formpage']]: isOnFormPage,
        })}
      >
        <div
          className={cn(css['text-input__wrapper'], {
            [css['text-input__wrapper--error']]: errorMsg,
            [css['text-input__wrapper--disabled']]: rest.disabled,
            [css['text-input__wrapper--formpage']]: isOnFormPage,
            [css['text-input__wrapper--icon']]: showIcon,
          })}
        >
          <input
            {...rest}
            className={cn(css['text-input'], css['input-calendar'])}
            type="date"
            id={id}
            name={name}
            autoComplete="off"
            data-date-inline-picker="true"
            onChange={(event) => {
              rest?.onChange && rest.onChange(event);
              setClearButtonVisible(true);
            }}
            ref={ref}
          />
          {showIcon && (
            <div className={cn(css['text-input--icon-right'], css['calendar-icon'])} aria-hidden="true">
              <FaIcon name={FaIcons.CalendarAlt} />
            </div>
          )}
          {showClearButton && clearButtonVisible && (
            <div
              className={cn(css['text-input--icon-right'], css['close-icon'])}
              aria-hidden="true"
              tabIndex={0}
              onKeyPress={(): void => {
                onClearDate && onClearDate();
                setClearButtonVisible(false);
              }}
              onClick={(): void => {
                onClearDate && onClearDate();
                setClearButtonVisible(false);
              }}
            >
              <FaIcon name={FaIcons.Times} />
            </div>
          )}
        </div>
      </FormField>
    );
  },
);
DateInput.displayName = 'DateInput';
export default DateInput;
