﻿import React, { FC, useState } from 'react';
import { Button, Theme, Icons, Icon, IconSize } from '@in/component-library';

import './LandingPage.scss';
import { useDocTitle } from '../../hooks/CustomHooks';

const LandingPage: FC = () => {
  const [loading, setLoading] = useState(false);
  useDocTitle('Log in');
  const logIn = (event: any) => {
    event.preventDefault();
    window.location.href = '/api/session/login';
    setLoading(true);
  };

  return (
    <div className="landingpage">
      <h1 className="landingpage--heading">Velkommen!</h1>
      <div className="landingpage--text">
        <p>Dere har kommet til Norwegian Innovation Cluster-programmet.</p>
        <p>
          Norwegian Innovation Clusters er et statlig finansiert klyngeprogram som skal bidra til
          verdiskapning gjennom bærekraftig innovasjon.
        </p>
      </div>

      {loading ? (
        <div className="login-spinner">
          <Icon name={Icons.Spinner} size={IconSize.Small} />
        </div>
      ) : (
        <>
          <Button theme={Theme.Neutral} onClick={logIn}>
            Logg inn
          </Button>
          <div></div>
        </>
      )}

      <div className={'landingpage__admin-login'}>
        <p>
          <a href="/api/session/login?domainHint=commonaad">Ansatt i Innovasjon Norge? Klikk her.</a>
        </p>
      </div>
    </div>
  );
};
export default LandingPage;
