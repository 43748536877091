import React, { FC, lazy, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { StatusIconType } from 'src/components/StatusIcon/StatusIcon';
import { SideNav, SidenavItem, SidenavItemSection } from '@in/component-library';
import { CustomRouteObject } from '../../../routeConfigs/types';
import { useApplicationRoutes } from '../../../hooks/CustomHooks';
import { developmentApplicationRoutePaths } from '../../../routeConfigs/application/DevelopmentApplicationRouteConfig';
import { DevelopmentWithMetadataDto, ProjectApplicationTypeEnum } from '../../../models';
import { emptyGuid } from '../../../utils/Guid';
import cn from 'classnames';
import {
  developmentApplicationFinancingSourcesIsValid,
  getNumberOfMissingFields,
} from '../../../store/storeValidationFunctions/storeValidationFunctions';
import useDevelopmentApplication from '../../../hooks/developmentApplication/use-development-application';
import css from './ApplicationMenu.module.scss';
import { sumAllPropertiesInCollection } from '../../../hooks/use-development-budget';
const StatusIcon = lazy(() => import('src/components/StatusIcon/StatusIcon'));

interface SideNavProps {
  hidden?: boolean;
  applicationId: string | null;
}

const DevelopmentApplicationMenu: FC<SideNavProps> = ({
  hidden = false,
  applicationId,
}: PropsWithChildren<SideNavProps>) => {
  const location = useLocation();
  const { applicationRoutes } = useApplicationRoutes(location.pathname);
  const { developmentApplication } = useDevelopmentApplication(applicationId);

  const [hiddenInternal, setHiddenInternal] = useState<boolean>(hidden);

  useEffect(() => {
    setHiddenInternal(hidden);
  }, [hidden]);

  const getStatusIcon = useCallback(
    (path: string) => {
      const missingFieldsFromStore = validatePage(path, developmentApplication);

      return <StatusIcon type={getIconType(missingFieldsFromStore)} />;
    },
    [developmentApplication],
  );
  const menuItemsMemo = React.useMemo(() => {
    const linkItems: Array<SidenavItemSection> = applicationRoutes[0].children.map((routeObject) => {
      const childItems: (SidenavItem | undefined)[] | undefined = routeObject.children
        ?.map<SidenavItem | undefined>((item: CustomRouteObject) => {
          return {
            icon: getStatusIcon(item.path),
            text: item.name,
            href: item.path + location.search,
            active: location.pathname === item.path,
            disabled: false,
          };
        })
        .filter((item) => item != null);

      return {
        id: `${routeObject.path}`,
        href: childItems?.length === 0 ? routeObject.path + location.search : undefined,
        header: routeObject.name,
        active: location.pathname === routeObject.path,
        disabled: false,
        children: childItems != null ? (childItems as SidenavItem[]) : [],
      };
    });

    return linkItems;
  }, [location.pathname, location.search, getStatusIcon, applicationRoutes]);

  return hiddenInternal ? (
    <></>
  ) : (
    <SideNav
      sections={menuItemsMemo}
      renderLink={(sidenavItem: SidenavItem) => {
        if (sidenavItem.disabled) {
          return (
            <span>
              {sidenavItem.icon}
              {sidenavItem.text}
            </span>
          );
        }
        return (
          <Link
            to={sidenavItem.href || '#'}
            className={cn({ [css['application-menu--active']]: sidenavItem.active })}
          >
            {sidenavItem?.listLevel == null && sidenavItem.icon}
            {sidenavItem?.listLevel != null && sidenavItem.listLevel} {sidenavItem.text}
          </Link>
        );
      }}
    />
  );
};
export default DevelopmentApplicationMenu;

function getIconType(numberOfMissingFields: number) {
  if (numberOfMissingFields === 0) {
    return StatusIconType.COMPLETE;
  } else if (numberOfMissingFields > 0) {
    return StatusIconType.IN_PROGRESS;
  } else {
    return StatusIconType.PENDING;
  }
}

export function validatePage(
  path: string,
  developmentApplication: DevelopmentWithMetadataDto | undefined,
): number {
  switch (path) {
    case developmentApplicationRoutePaths.projectAbout: {
      return validateProjectAboutPage(developmentApplication);
    }
    case developmentApplicationRoutePaths.projectParticipants:
      return validateProjectParticipantsPage(developmentApplication); //validPages.developmentProjectParticipantsMissingFields;
    case developmentApplicationRoutePaths.activitiesProjectsPath:
      return validatePlannedActivitiesPage(developmentApplication); //validPages.developmentActivitiesProjectsPathMissingFields;
    case developmentApplicationRoutePaths.societalEffectsPath:
      return validateSocietalEffectsPage(developmentApplication); //validPages.developmentSocietalEffectsPathMissingFields;
    case developmentApplicationRoutePaths.budgetPath:
      return validateFinancingPage(developmentApplication); //validPages.developmentFinancingBudgetPageMissingFields;
    default:
      return -1;
  }
}

/**
 * Validate "Om dette prosjektet" and "Prosjektdeltagere"
 * @param developmentApplication
 */
function validateProjectAboutPage(developmentApplication: DevelopmentWithMetadataDto | undefined) {
  const aboutProjectIsValid: boolean[] = [
    developmentApplication?.project?.goal != null && developmentApplication?.project?.goal.id !== emptyGuid(),
    developmentApplication?.project?.focusAreas != null &&
      developmentApplication?.project?.focusAreas.length > 0,
    developmentApplication?.project?.startDate != null,
    developmentApplication?.project?.endDate != null,
    developmentApplication?.project?.projectJustification != null &&
      developmentApplication?.project?.projectJustification != '',
    developmentApplication?.project?.goalDescription != null &&
      developmentApplication?.project?.goalDescription != '',
  ];

  const applicationType = developmentApplication?.type;

  // Validate only if "Opplæring" and "Økt kunnskap om internasjonale markeder"
  if (
    applicationType === ProjectApplicationTypeEnum.Training ||
    applicationType === ProjectApplicationTypeEnum.InternationalMarketsKnowledge
  ) {
    aboutProjectIsValid.push(
      developmentApplication?.project?.applicationTypeSpecificInformation?.trainingDescription != null &&
        developmentApplication?.project?.applicationTypeSpecificInformation?.trainingDescription != '',
    );
  }

  // Validate only if "Utdanningstilbud"
  if (
    applicationType === ProjectApplicationTypeEnum.EducationalOperations ||
    developmentApplication?.type === ProjectApplicationTypeEnum.Announcement
  ) {
    aboutProjectIsValid.push(
      developmentApplication?.project?.applicationTypeSpecificInformation?.educationalOfferingsDescription !=
        null &&
        developmentApplication?.project?.applicationTypeSpecificInformation
          ?.educationalOfferingsDescription != '',
    );
  }

  // Validate only if "Forstudie"
  if (applicationType === ProjectApplicationTypeEnum.FeasibilityStudy) {
    aboutProjectIsValid.push(
      developmentApplication?.project?.applicationTypeSpecificInformation?.feasibilityStudyInnovationLevel !=
        null,
    );
  }

  // Validate only if "Forstudie", "Innovasjonssamarbeid", "Infrastruktur", "Messedeltagelse", "Utvikling av nye eller forsterkede internasjonale markedsposisjoner"
  if (
    applicationType === ProjectApplicationTypeEnum.FeasibilityStudy ||
    applicationType === ProjectApplicationTypeEnum.InnovationCooperation ||
    applicationType === ProjectApplicationTypeEnum.Investments ||
    applicationType === ProjectApplicationTypeEnum.ConferenceParticipation ||
    applicationType === ProjectApplicationTypeEnum.InternationalMarketPositionsDevelopment
  ) {
    aboutProjectIsValid.push(
      developmentApplication?.project?.applicationTypeSpecificInformation?.futureClusterValueDescription !=
        null &&
        developmentApplication?.project?.applicationTypeSpecificInformation?.futureClusterValueDescription !=
          '',
    );
  }

  const aboutProjectIsValidMissingFields = getNumberOfMissingFields(
    aboutProjectIsValid,
    aboutProjectIsValid.length,
  );

  return aboutProjectIsValidMissingFields;
}

function validateProjectParticipantsPage(developmentApplication: DevelopmentWithMetadataDto | undefined) {
  const projectParticipantsPageIsValid: boolean[] = [
    developmentApplication?.project?.participants?.participantsDescription != null &&
      developmentApplication?.project?.participants?.participantsDescription != '',
  ];

  const projectParticipantsPageIsValidMissingFields = getNumberOfMissingFields(
    projectParticipantsPageIsValid,
    1,
  );
  return projectParticipantsPageIsValidMissingFields;
}

/**
 * Validate "Framdrift og prosjektaktiviteter"
 * @param developmentApplication
 */
function validatePlannedActivitiesPage(developmentApplication: DevelopmentWithMetadataDto | undefined) {
  if (developmentApplication?.plannedActivities != null) {
    const projectActivitiesIsValid: boolean[] = [developmentApplication?.plannedActivities.length > 0];

    const projectActivitiesIsValidMissingFields = getNumberOfMissingFields(projectActivitiesIsValid, 1);

    return projectActivitiesIsValidMissingFields;
  }
  return -1;
}

/**
 * Validate Samfunns- og miljøeffekter
 * @param developmentApplication
 */
function validateSocietalEffectsPage(developmentApplication: DevelopmentWithMetadataDto | undefined) {
  let boolArray: boolean[] = [];
  //dersom notApplicable er null er ikke siden påbegynt
  if (developmentApplication?.impact?.notApplicable === null) {
    return getNumberOfMissingFields([false, false], 2);
  }

  if (!developmentApplication?.impact?.notApplicable && developmentApplication?.impact?.impacts) {
    boolArray = [
      !developmentApplication?.impact?.notApplicable ?? false,
      developmentApplication.impact.impacts.length > 0,
    ];
  }
  if (developmentApplication?.impact?.notApplicable) {
    boolArray = [
      developmentApplication?.impact?.notApplicable ?? false,
      developmentApplication?.impact?.notApplicableComment != null &&
        developmentApplication?.impact?.notApplicableComment !== '',
    ];
  }

  return getNumberOfMissingFields(boolArray, 2);
}

/**
 * Validate "Finansieringsplan" and "Prosjektbudsjett"
 * @param developmentApplication
 */
function validateFinancingPage(developmentApplication: DevelopmentWithMetadataDto | undefined) {
  if (developmentApplication?.financing?.sources != null) {
    const costTotal = sumAllPropertiesInCollection(developmentApplication.costs ?? [], [
      'applicantAmount',
      'participantsAmount',
    ]);
    const financeTotal = sumAllPropertiesInCollection(developmentApplication.financing.sources, ['amount']);
    if (costTotal + financeTotal === 0) {
      return -1;
    }
    const financingPlanPageIsValid = developmentApplicationFinancingSourcesIsValid(
      developmentApplication.costs ?? [],
      developmentApplication.financing.sources,
      developmentApplication.type,
    );
    const missingFields = financingPlanPageIsValid.filter((isValid) => !isValid).length;

    return missingFields;
  }
  return -1;
}
