import React from 'react';
import { FocusArea } from '../../models';
import { Control, Controller } from 'react-hook-form';
import { Button, ButtonVariant, TextArea, Theme } from '@in/component-library';
import { InputMaxLength } from '../../enums/InputMaxLength';
import { RHFRequiredNotEmptyMediumMaxLength } from '../../utils/FormValidations';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';
import { GoalsAndFocusAreasFormInputs } from '../../Application/themePageSections/ClustersGoalsAndFocusAreas';

export interface FocusAreaAccordionInputFieldsProps {
  showSaveButton?: boolean;
  saveProcessActive: boolean;
  onRemove: (index: number, focusArea: FocusArea) => void;
  onSave: (index: number, isDirty: boolean) => void;
  index: number;
  label?: string;
  control: Control<GoalsAndFocusAreasFormInputs, any>;
  fields: FieldArrayWithId<GoalsAndFocusAreasFormInputs, 'focusAreas', 'focusAreaInternalId'>;
}

const FocusAreaAccordionInputFields = React.forwardRef<
  HTMLTextAreaElement,
  FocusAreaAccordionInputFieldsProps
>(
  (
    {
      control,
      index,
      onRemove,
      onSave,
      showSaveButton = true,
      label = 'Overordnet beskrivelse av fokusområde',
      ...props
    }: FocusAreaAccordionInputFieldsProps,
    ref,
  ) => {
    return (
      <>
        <Controller
          control={control}
          name={`focusAreas.${index}.description` as const}
          rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              errorMsg={fieldState.error?.message}
              onBlur={() => {
                field.onBlur();
                onSave && onSave(index, fieldState.isDirty);
              }}
              ref={ref}
              label={label}
              maxCount={InputMaxLength.medium}
              maxLength={InputMaxLength.medium}
              rows={3}
              disableResize={true}
            />
          )}
        />

        <div className="button-container button-container--space-between">
          <Button
            disabled={props.saveProcessActive}
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            onClick={() => {
              onRemove && onRemove(index, props.fields);
            }}
            type={'button'}
          >
            Fjern
          </Button>
          {showSaveButton && (
            <Button
              disabled={props.saveProcessActive}
              theme={Theme.Neutral}
              variant={ButtonVariant.Outlined}
              type={'button'}
              onClick={() => {
                onSave && onSave(index, true);
              }}
            >
              Lagre
            </Button>
          )}
        </div>
      </>
    );
  },
);
FocusAreaAccordionInputFields.displayName = 'FocusAreaAccordionInputFields';
export default FocusAreaAccordionInputFields;
