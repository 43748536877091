﻿import React, { FC } from 'react';
import { Footer as BaseFooter } from '@in/component-library';
import './Footer.scss';

export const Footer: FC = () => {
  return (
    <BaseFooter className={'override__footer'}>
      <p>
        Spørsmål om Flerbedriftsportalen?<br></br>
        <a href="mailto:klyngeportalen@innovasjonnorge.no">Ta kontakt</a>
      </p>
      <p>© {new Date().getFullYear()}. Alle rettigheter</p>
    </BaseFooter>
  );
};
