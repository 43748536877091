﻿import React, { FC } from 'react';
import { Header } from '../Header/Header';
import { Footer as BaseFooter } from '../Footer/Footer';
import { reactPlugin } from 'src/ApplicationInsights/ApplicationInsights';
import useUser from '../../hooks/user/use-user';
import classNames from 'classnames';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

type Props = {
  user: ReturnType<typeof useUser>;
  children: React.ReactNode;
};
const Layout: FC<Props> = ({ children, user: userProp }) => {
  const { isUserLoggedIn, isUserRegistered, loadingUser } = userProp;

  const classes = classNames('layout-wrapper', {
    isLoggedOut: !isUserLoggedIn || !isUserRegistered,
    isLoggedIn: isUserLoggedIn,
    isPending: loadingUser,
  });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div className={'layout-wrapper'}>
        <div className={`container ${classes} `}>
          {isUserLoggedIn && <Header />}
          {children}

          {isUserLoggedIn && (
            <footer>
              <BaseFooter />
            </footer>
          )}
        </div>
      </div>
    </AppInsightsContext.Provider>
  );
};
export default Layout;
